body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#logo {
    margin: 0;
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
    display: flex;
    padding: 4px;
    align-items: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    border: 1px solid;
    overflow: hidden;
}

#logo img {
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
}

